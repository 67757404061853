import React from "react";
import {
  FaYoutube,
  FaDiscord,
  FaFacebook,
  FaStore,
  FaDollarSign,
  FaTwitch,
  FaTiktok,
} from "react-icons/fa";

import { FiChevronRight } from "react-icons/fi";
import { MdCasino } from "react-icons/md";

import {
  Container,
  ContentLogo,
  ImageLogo,
  Button,
  Title,
  Line,
  SocialButtons,
  SocialButton,
  Email,
} from "./home.styles";

import instagram from "../../assets/Instagram.svg";
import { analysticsEvent } from "../../services/index";

const Home = () => {
  return (
    <Container>
      <ContentLogo>
        <ImageLogo />
      </ContentLogo>

      <Title>LIPINHO</Title>

      <Button
        href="https://www.youtube.com/@lipinho"
        onClick={() => analysticsEvent("ON_CLICK_YOUTUBE")}
      >
        <FaYoutube size={30} color="#018CF6" />
        <p>
          YouTube<span>Inscreva-se.</span>
        </p>
        <FiChevronRight color="#9c9c9c" size={18} />
      </Button>

      <Button
        href="https://www.twitch.tv/lipinhor"
        onClick={() => analysticsEvent("ON_CLICK_TWITCH")}
      >
        <FaTwitch size={30} color="#018CF6" />
        <p>
          Twitch<span>Live todos os dias.</span>
        </p>
        <FiChevronRight color="#9c9c9c" size={18} />
      </Button>

      <Button
        href="https://www.instagram.com/LipinhOx_"
        onClick={() => analysticsEvent("ON_CLICK_INSTAGRAM")}
      >
        <img src={instagram} alt="instagram" />
        <p>
          Instagram<span>@lipinhox_</span>
        </p>
        <FiChevronRight color="#9c9c9c" size={18} />
      </Button>

      <Button
        href="https://bc.game/i-liipinhobc-n/"
        onClick={() => analysticsEvent("ON_CLICK_ESTRELABET")}
      >
        <MdCasino size={30} color="#018CF6" />
        <p>
          BC Game<span>Aposte comigo!</span>
        </p>

        <FiChevronRight color="#9c9c9c" size={18} />
      </Button>

      <Button
        href="https://www.tiktok.com/@lipinhox"
        onClick={() => analysticsEvent("ON_CLICK_TIKTOK")}
      >
        <FaTiktok size={30} color="#018CF6" />
        <p>
          Tiktok<span>@lipinhox</span>
        </p>

        <FiChevronRight color="#9c9c9c" size={18} />
      </Button>

      <Line />

      <SocialButtons>
        <SocialButton
          href="https://www.facebook.com/LiipinhO"
          onClick={() => analysticsEvent("ON_CLICK_FACEBOOK")}
        >
          <FaFacebook size={25} color="#018CF6" />
        </SocialButton>
        <SocialButton
          href="https://discord.gg/EZEeYwCqRs"
          onClick={() => analysticsEvent("ON_CLICK_DISCORD")}
        >
          <FaDiscord size={25} color="#018CF6" />
        </SocialButton>
        <SocialButton
          href="https://www.streamar.com.br/LipinhO"
          onClick={() => analysticsEvent("ON_CLICK_DONATE")}
        >
          <FaDollarSign size={25} color="#018CF6" />
        </SocialButton>
        <SocialButton href="https://streamelements.com/lipinhor/store">
          <FaStore
            size={25}
            color="#018CF6"
            onClick={() => analysticsEvent("ON_CLICK_STORE")}
          />
        </SocialButton>
      </SocialButtons>

      <Email>contato@lipinho.com</Email>
    </Container>
  );
};

export default Home;
