import firebase from "firebase/app";
import "firebase/analytics";

const configSite = {
  apiKey: "AIzaSyD7tfNKTVRXCGmw-eATAm4FUoimETFtSug",
  authDomain: "liipinho.firebaseapp.com",
  projectId: "liipinho",
  storageBucket: "liipinho.appspot.com",
  messagingSenderId: "810215371982",
  appId: "1:810215371982:web:7d27c09fb0b5f5b98d0c9f",
  measurementId: "G-4K7EH8JZKW",
};

if (!firebase.apps.length) {
  firebase.initializeApp(configSite);
} else {
  firebase.app();
}

export default firebase;

const analyticsSite = () => firebase.analytics();

export function analysticsEvent(event, params) {
  return analyticsSite().logEvent(event, params);
}
