import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #0a0b16;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img``;
