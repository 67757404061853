import styled from "styled-components";

import logo from "../../assets/logo.png";

const Container = styled.div`
  width: 100%;
  height: 100vh;

  background: linear-gradient(166.02deg, #00bef6 3.92%, #0080f6 99.63%);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.h1`
  color: #fefefe;

  font-size: 14pt;
  font-weight: 600;

  margin-bottom: 10px;
`;

const ContentLogo = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 50px;
  margin-bottom: 5px;

  background: #fefefe;

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 2px 2px 2px 2px rgba(255, 255, 255, 0.3);
`;

const ImageLogo = styled.img.attrs({ src: logo })`
  width: 70px;
  height: 70px;
`;

const Button = styled.a`
  text-decoration: none;

  max-width: 260px;
  width: 75%;
  height: 60px;
  margin-top: 10px;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;

  background: #ffffff;

  display: flex;
  align-items: center;

  p {
    width: 95%;
    text-align: left;
    font-size: 12pt;
    font-weight: 600;
    color: #535353;
    margin-left: 15px;

    display: flex;
    flex-direction: column;

    span {
      font-size: 9pt;
      font-weight: 400;
      color: #9c9c9c;
    }
  }

  &:hover {
    background: #f5f3f3;
  }
`;

const Line = styled.hr`
  margin-top: 30px;
  margin-bottom: 5px;

  max-width: 200px;
  width: 30%;
`;

const SocialButtons = styled.div`
  max-width: 375px;
  width: 90%;
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const SocialButton = styled.a`
  height: 55px;
  width: 55px;
  border-radius: 10px;
  background-color: #ffffff !important;
  margin-right: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #f5f3f3;
  }
`;

const Email = styled.p`
  font-size: 11pt;
  color: #fefefe;
  font-weight: 300;
`;

export {
  Container,
  ContentLogo,
  ImageLogo,
  Button,
  Title,
  Line,
  SocialButtons,
  SocialButton,
  Email,
};
