import React from "react";

import { Container, Image } from "./only.styles";

import vaiOrar from "../../assets/index.jpeg";

const Only = () => {
  return (
    <Container>
      <Image src={vaiOrar} />
    </Container>
  );
};

export default Only;
