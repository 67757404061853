import styled from "styled-components";

export const Container = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
background: blueviolet;
`
;

export const Image = styled.img`
width: 100%;
@media screen and (min-width: 768px) {
    height: 100%;
}
`
;
