import React from "react";

import { Container, Image } from "./programacao.styles";

import programacaoImg from "../../assets/programacao.png";

const Programacao = () => {
  return (
    <Container>
      <Image src={programacaoImg} />
    </Container>
  );
};

export default Programacao;
